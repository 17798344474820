@media screen and (max-width: 2000px) {
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap");

.booking-ticket {
  width: 155%;
  display: flex;
}

.input-field-container{
  width:72%;
  margin-left:14%;
  border-radius:10px;
  box-shadow: 2px 2px 10px #b1abab;
}

.main-text-field{
  width:33%;
  padding: 0px 10px;
  float:left;
}
.list-group {
  width: 60%;
  margin: 30px;
}

.side-info {
  width: 30%;
  margin-top: 30px;
  padding-top: 30px;
  border: 3px solid rgb(217, 216, 216);
  border: 2px solid #0a2254;
  border-radius: 10px;
}

.side-info-list {
  width: 85%;
}

.search-box {
  width: 85%;
  margin-left: 30px;
}

.browse-title {
  margin-left: 30px;
  margin-top: 30px;
}

.dform {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 45%;
  font-family: "Quicksand", sans-serif;
}

.dform1 {
  /* text-align: center; */
  color: black;
  width: 30%;
  margin-top: 30px;
  margin-bottom: 40px;
  margin-left: 45%;
  font-family: "Quicksand", sans-serif;
}

.cordion {
  margin-top: 60px;
  justify-content: center;
  width: 70%;
  margin-left: 15%;
}

.rowcont {
  width: 90%;
  margin-left: 5% !important;
}

.containercont {
  margin-top: 50px;
}

.btn-bookbtn {
  background-color: #0a2254;
  color: red;
  outline:none;
  border-radius:0px 10px 0px 10px;
  width: 150px;
  height: 45px;
  margin: 20px 40%;
  box-shadow:2px 2px 10px #000;
  color: white;
  transition:0.4s ;
  -webkit-border-radius:0px 10px 0px 10px;
  -moz-border-radius:0px 10px 0px 10px;
  -ms-border-radius:0px 10px 0px 10px;
  -o-border-radius:0px 10px 0px 10px;
  -webkit-transition:0.4s ;
  -moz-transition:0.4s ;
  -ms-transition:0.4s ;
  -o-transition:0.4s ;
}
.btn-bookbtn:hover {
  background-color: rgb(238, 196, 8);
  border:none;
  color: black;
}
.btn-bookbtn:active {
  box-shadow:none;
}

.neeraj:active {
  border: 3px solid transparent;
}

.bdd {
  box-sizing: border-box;
  width: "6%";
  float: left;
}



/* accordian start */

.main-container-accordian{
  max-height:auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-container-header {
  height: 50px;
  box-shadow: 2px 2px 10px #b1abab;
  border-radius: 10px 10px 0px 0px;
  width: 72%;
  padding: 10px;
  display: flex;
  background-color: #0a2254;
  box-shadow: 2px 2px 10px #000;
  justify-content: center;
  /* background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTOoqMG-BqXidw7bQTVWxjU52Fk8LOdIt1L_gOXpZE78hP9MENxmUeBoCFJlZjp-6AqzU&usqp=CAU"); */
  /* background-repeat: no-repeat; */
  background-size: 100% 100%;
}
.header-content {
  box-sizing:border-box;
  font-size: 12px;
  width: 80%;
  font-weight: 500;
  color: #dfdfdf;
}
.main-container-header-content,
.header-content {
  text-transform: capitalize;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.main-container-header-content {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  width: 20%;
}
.togglerS{
  position: relative;
  top: -8px;
}
.main-container-body {
  background-image: url("https://img.freepik.com/premium-vector/abstract-grey-white-waves-lines-pattern_29865-703.jpg?w=2000");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 300px;
  border-radius: 0px 0px 10px 10px;
  width: 72%;
  box-shadow: 2px 6px 10px #b1abab;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-container-body-carousel {
  height: 100%;
  border-radius: 10px;
  padding-left: 50px;
  width: 90%;
  background-color: transparent;
  overflow: auto;
  overflow-y: hiddden;
  white-space: nowrap;
}
.main-container-body-carousel-inner {
  width: 7%;
  border-radius: 5px;
  height:80px;
  display: inline-block;
  margin: 0px 0px 0px 13px;
}

.calendar {  border:1px solid red;
  opacity: 70%;
  filter: blur(1px);
  box-shadow: 1px 1px 3px grey;
  width: 3.86rem;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -10px;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  border-radius: 0.375rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-filter: blur(1px);
}
.calendar:hover {
  cursor: pointer;
  border: 1px solid rgb(240, 127, 127);
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  filter: blur(0px);
  opacity: 100%;
  -webkit-filter: blur(0px);
}
.calendar .calendar-week {
  background-color: #f10b0b;
  margin-bottom: -6px;
  color: #fff;
  border-radius: 0.375rem 0.375rem 0 0;
  padding: 0.25rem;
  font-weight: 600;
}
.main-container-body-carousel-inner:hover .calendar-week {
  background-color: #f10b0b;
  filter: blur(0px);
  opacity: 100%;
}
.calendar .calendar-day {
  font-size: 18px;
  padding: 0.25rem;
  white-space: break-spaces;
  border-top: 0;
  height: 50px;
  margin-top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0.375rem 0.375rem;
  color: #000000;
  font-weight: 700;
  margin-bottom: 2rem;
}
.calender-focus {
  border: 1px solid red;
  box-shadow: 1px 1px 3px grey;
  width: 3.86rem;
  display: flex;
  line-height: 1.1;
  font-weight: bold;
  color: #fff;
  margin-left: -10px;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  white-space: break-spaces;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transform: scale(1.11);
  transition: 0.4s;
  overflow: hidden;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  -webkit-transform: scale(1.11);
  -moz-transform: scale(1.11);
  -ms-transform: scale(1.11);
  -o-transform: scale(1.11);
  filter: blur(0px);
  -webkit-filter: blur(0px);
  opacity: 100%;
  filter: blur(0px);
}
.detailShow {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.slct {
  font-size: 44px;
  color: grey;
  font-weight: bold;
}
.prc {
  font-size: 28px;
  font-weight: 500;
  color: #000;
}

.form-check{
  font-size: 20px;
  }

}


/* responsive 2 */


@media screen and (max-width: 1280px) {

  .main-container-body-carousel-inner {
    width: 11%;
    border-radius: 5px;
    height:80px;
    display: inline-block;
    margin: 0px 0px 0px 13px;
  }

}

/* responsive 3 */


@media screen and (max-width: 980px) {

  .slct {
    font-size: 30px;
    color: grey;
    font-weight: bold;
  }
  .prc {
    font-size: 22px;
    font-weight: 500;
    color: #000;
  }

  .main-container-body-carousel-inner {
    width: 15%;
    border-radius: 5px;
    height:80px;
    display: inline-block;
    margin: 0px 0px 0px 13px;
  }

}


/* responsive 4 */


@media screen and (max-width: 670px) {

  .main-text-field{
    width:100%;
    padding: 0px 10px;
    float:left;
  }

  .slct {
    font-size: 30px;
    color: grey;
    font-weight: bold;
  }
  .prc {
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }

  .main-container-body-carousel-inner {
    width: 25%;
    border-radius: 5px;
    height:80px;
    display: inline-block;
    margin: 0px 0px 0px 13px;
  }
  .btn-bookbtn {
    margin-left:25%;
  }
  .header-content {
    font-size: 8px;
  }
  .main-container-header-content {
    font-size: 12px;
  }
  .input-field-container{
    margin-left:15%;
    border-radius:10px;
    box-shadow: 2px 2px 10px #b1abab;
}
  .dform1 {
    width: 100%;
    margin-left:250px;
  }

}

/* responsive 5 */


@media screen and (max-width: 470px) {

  .main-container-header {
    background-image: url("https://www.shutterstock.com/image-vector/dark-blue-golden-abstract-tech-260nw-2011506185.jpg");
  }

  .slct {
    font-size: 21px;
    color: grey;
    font-weight: bold;
  }
  .prc {
    font-size: 13px;
    font-weight: 500;
    color: #000;
  }

  .main-container-body-carousel-inner {
    width: 25%;
    border-radius: 5px;
    height:80px;
    display: inline-block;
    margin: 0px 0px 0px 13px;
  }
  .main-container-accordian{
    max-height:auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .main-container-body {
    width: 90%;
  }
  .main-container-header {
    width: 90%;
  }
  .dform1 {
    width: 50%;
    margin-left:130px;
  }

  .btn-bookbtn {
    margin-left:20%;
  }
  .header-content {
    font-size: 13px;
  }
  .main-container-header-content {
    font-size: 15px;
  }
  .main-text-field{
    width:100%;
    padding: 0px 10px;
    float:left;
  }
  .input-field-container{
    width:90%;
    border-radius:10px;
    box-shadow: 2px 2px 10px #b1abab;
    margin-left:20px;
  }

}

/* heading */
#btn-theme{
  width: 60px;
  height: 60px;
  border: 1px solid #f00;
  text-align: center;
  border-radius: 60px;
  background: #f00;
  cursor: pointer;
}
#btn-theme:hover #btn-theme-inner{
  font-size: 40px;
  transition:.3s ease-in-out;
}
#btn-theme-inner{
  color:#fff;
  font-size: 30px;
}
#heading::before{
  content: '';
  border: 2px solid #0c2e76;
  width: 87px;
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 0;
  margin: 0px auto;
  z-index: 1;
}
#heading::after{
  content: '';
  border: 1px solid #dfdfdf;
  width: 500px;
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 0;
  margin: 0px auto;
}