@media screen and (max-width: 2000px) {
  .main-container{
    height:86vh;
    padding:50px 130px;
    width:100vw;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .in-container-carousel{
    transition:.3s linear;
    height:100%;
    /* width:60%; */
    display:flex;
    justify-content: center;
    align-items: center;
    background:#fff;
    border-radius: 25px 0px 0px 25px;
    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    -webkit-transition:.3s linear;
    -moz-transition:.3s linear;
    -ms-transition:.3s linear;
    -o-transition:.3s linear;
}






/* info */


.in-container-info{
  background-image:url("https://media.istockphoto.com/id/672491020/vector/light-grey-abstract-technology-background.jpg?s=612x612&w=0&k=20&c=sEYd36iUQLoAaA3qLGmLPozmz_NHJaLQyHzmw5s0Ywk=");
  background-repeat: no-repeat;
  background-size:100% 100%;
  height:100%;
  width:40%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 25px 25px 0px;
  box-shadow:0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transition:.3s linear;
  -webkit-border-radius: 0px 25px 25px 0px;
  -moz-border-radius: 0px 25px 25px 0px;
  -ms-border-radius: 0px 25px 25px 0px;
  -o-border-radius: 0px 25px 25px 0px;
  -webkit-transition:.3s linear;
  -moz-transition:.3s linear;
  -ms-transition:.3s linear;
  -o-transition:.3s linear;
}
.in-container-info:hover{
  box-shadow:0px 0px 20px rgba(119, 119, 119, 0.9);
}
.in-container-carousel:hover{
  box-shadow:0px 0px 20px rgba(119, 119, 119, 0.9);
}


.explore-btn{
  background-image:linear-gradient(to right, #1d4eb8, #020f29);
  border: none;
  border-radius:0px 10px 0px 10px;
  box-shadow:-5px -5px 10px rgba(355,355,355,0.1),	  5px 5px 5px rgba(0,0,0,0.35),	  -5px -5px 10px rgba(355,355,355,0.1),	  5px 5px 10px rgba(0,0,0,0.35);
  transition:.6s linear;
  width: 150px;
  height: 40px;
  color:white;
  -webkit-transition:.6s linear;
  -moz-transition:.6s linear;
  -ms-transition:.6s linear;
  -o-transition:.6s linear;
  margin-top:5px;
  -webkit-border-radius:0px 10px 0px 10px;
  -moz-border-radius:0px 10px 0px 10px;
  -ms-border-radius:0px 10px 0px 10px;
  -o-border-radius:0px 10px 0px 10px;
}
.explore-btn:hover{
  background-image:linear-gradient(to right, #092d75, #020f29);
}
.explore-btn:active{
  background-image:linear-gradient(to right, #dbd6d6, #dbd6d6);
  color:#000;
  transition:0s linear;
  border:1px solid #000;
  -webkit-transition:0s linear;
  -moz-transition:0s linear;
  -ms-transition:0s linear;
  -o-transition:0s linear;
}

.info{
  padding:10px 30px 20px 30px;
  text-align: center;
  font-size:28px;
  line-height:normal;
  font-family: 'Great Vibes', cursive;
  color:#000;
}
.info::first-letter{
  font-weight: bold;
  color:red;
}
.info::selection{
  background:rgb(255, 208, 0);
}

.dte{
  font-size: 24px;
  font-weight: bold;
  background-image:linear-gradient(to right, transparent, #f4d52f, transparent);
}

.date-container{
  width:100%;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  padding:0px 30px;
}

}


/* responsive 2 */


@media screen and (max-width: 1250px) {

  .main-container{
    padding:50px 40px;
  }
}


/* responsive 3 */


@media screen and (max-width: 1100px) {

  .main-container{
    padding:50px 100px;
    height:auto;
    flex-direction: column;
  }
  .in-container-carousel{
    height:300px;
    width:100%;
    border-radius: 25px 25px 0px 0px;
    -webkit-border-radius: 25px 25px 0px 0px;
    -moz-border-radius: 25px 25px 0px 0px;
    -ms-border-radius: 25px 25px 0px 0px;
    -o-border-radius: 25px 25px 0px 0px;
}
.in-container-info{
  height:400px;
  width:100%;
  border-radius: 0px 0px 25px 25px;
  box-shadow:0px 0px 40px rgba(0, 0, 0, 0.5);
}
}


/* responsive 4 */



@media screen and (max-width: 700px) {

  .in-container-info{
    height:400px;
  }
.info{
  font-size:22px;
}
.dte{
  font-size:16px;
}

}


/* responsive 5 */


@media screen and (max-width: 590px) {


  .in-container-carousel{
    height:200px;
}
.info{
  font-size:18px;
}

.date-container{
font-size: 10px;
}


}



@media screen and (max-width: 500px) {

  .main-container{
    padding:50px 20px;
  }

.info{
  font-size:20px;
}

.date-container{
font-size: 7px;
}


}