
@media screen and (max-width: 2000px) {


.main-container-ticket{
    filter: grayscale(40%);
    background-image:
    url("https://png.pngtree.com/background/20210711/original/pngtree-simple-white-diamond-background-picture-image_1151585.jpg");
    background-repeat: no-repeat;
    background-size: 110% 110%;
    transition:.3s;
    height:250px;
    width:45%;
    color:white;
    float:left;
    margin:1.5% 0% 1.5% 3.5%;
    border-radius:10px;
    color:#000;
    box-shadow:2px 2px 5px #000;
    -webkit-transition:.3s;
    -moz-transition:.3s;
    -ms-transition:.3s;
    -o-transition:.3s;
    overflow:hidden;
    -webkit-filter: grayscale(40%);
}   

.main-container-ticket:hover{
    filter: grayscale(0%);
    cursor:pointer;
    box-shadow: 2px 2px 20px #000;
    background-size: 110% 120%;
    transform:scale(1.06) ;
    -webkit-transform:scale(1.06) ;
    -moz-transform:scale(1.06) ;
    -ms-transform:scale(1.06) ;
    -o-transform:scale(1.06) ;
    -webkit-filter: grayscale(0%);
}
.imggDiv{
    min-height:100%;
    width:40%;
    float:left;
    padding:10px;   
}
.imggDiv img{
    width:100%;
    height:14rem;
    border-radius:10px;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    -ms-border-radius:10px;
    -o-border-radius:10px;
}
.contentDiv{
    height:98%;
    width:60%;
    float:left;
    font-size: 16px;
    padding:20px;
    overflow:hidden;
    border-left:2px solid rgb(204, 200, 200);
    border-radius:10px;
}
/* p{
        text-align: justify;
        overflow:auto;
        padding:0px 10px;
        font-weight: 500;
        height:175px;
} */

}

@media screen and (max-width: 1200px) {

    .main-container-ticket{
        height:200px;
    }

    .imggDiv img{
        height:11rem;
    }
    .contentDiv{
        font-size: 15px;
    }

    /* p{
        height:95px;
    } */


}

@media screen and (max-width: 1000px) {

    .main-container-ticket{
        height:170px;
    }

    .contentDiv{
        font-size: 12px;
    }

    .imggDiv img{
        height:9rem;
    }

    /* p{
        height:60px;
        font-size: 8px;
    } */


}

@media screen and (max-width: 900px) {

    .main-container-ticket{
        height:250px;
        width:80%;
        margin:3% 0% 0% 10%;
    }

    .imggDiv img{
        height:14rem;
    }

    .contentDiv{
        font-size: 17px;
    }

    /* p{
        height:110px;
        font-size: 12px;
    } */

}


@media screen and (max-width: 600px) {

    .main-container-ticket{
        height:200px;
    }

    .contentDiv{
        font-size: 12px;
    }
    .imggDiv img{
        height:11rem;
    }
    p{
font-size:10px;
}


}

@media screen and (max-width: 500px) {

    .main-container-ticket{
        height:150px;
        width:90%;
        margin:3% 0% 0% 5%;
    }

    .contentDiv{
        font-size: 9px;
    }
    .imggDiv img{
        height:8rem;
    }

    /* p{
        height:70px;
        font-size: 6.5px;
    } */


}