@media screen and (max-width: 2000px) {
.nav-bg{
    background-image: url("https://st.depositphotos.com/3930503/59792/i/450/depositphotos_597928206-stock-photo-light-blue-gradient-background-blue.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.loginbtn{
    margin-left: 30px;
}
.navbar-nav li a{
    color: white;
    margin-right: 25px;
} 
.navbar-nav li a:hover{
        color:#f8b41d;
}

.text-center i{
     /* color: red; */
     color: #ff4444;
    
}

.close-btn{
    margin-left: 360px;
}
/* .navbar{
    padding: 0%;
} */

.srchh{
    color:#ff4444;
}



.logintbtnn:hover{
    background-image: linear-gradient( to right, #dba32b, #e7a20c);
}

.logintbtnn{
    box-shadow: 2px 2px 5px #a3a1a1;
    background-image: linear-gradient( to right, #e6bb60, #e7a20c);
    width: 95px;
    height: 37px;
    font-weight:bold;
    border: none;
    border-radius:0px  10px 0px 10px;
    margin-left: 50px;
    margin-right: 20px;
    -webkit-border-radius:0px  10px 0px 10px;
    -moz-border-radius:0px  10px 0px 10px;
    -ms-border-radius:0px  10px 0px 10px;
    -o-border-radius:0px  10px 0px 10px;
}

.btn btn-danger:hover{
    border: 2px solid yellow;
    background-color: green;
    color:white;
} 

/* .btn-danger{
    margin-left: 40%;
} */


}
/* responsive */
@media screen and (max-width: 990px) {

    
    .loginbtn{
        margin-left: 30px;
    }

    .navbar-toggler{
        margin: 0px 600px 0px 10px;
        background-color:red;
     
     }
     .search-container{
         display: none;
     }
     .logintbtnn{
         margin-left: -120px;
         margin-right:20px ;
     }
     .navbar-nav{
        font-size:20px;
     }
  }

  label{
    color: red;
}
.pfl{
    margin-left:-220px;
}

.form-outline{
    width: 100%;
}
