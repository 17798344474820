.highlight {
    font-weight: 900;
    background-color:#448aff;
    color: white;
}
.highlight2{
    background-color: red;
}

.react-calendar__tile:disabled {
    background-color: #79aaff;
}
.react-calendar {
    width: 35rem;
    border-radius: 5px;
    border-color:  #448aff;
    padding: 1rem;
}
.react-calendar:hover{
    box-shadow: 1px 0px 10px 2px #448aff59;

}