
/* ticketcss */

@import url('https://fonts.googleapis.com/css?family=Oswald');

* {
  /* margin: 0;
  padding: 0;
  border: 0; */
  box-sizing: border-box;
  /* overflow: hidden; */
}

/* body {
  background-color: #dadde6;
  font-family: arial
} */

.fl-left {
  float: left
}

/* .fl-right {
  float: right
} */

/* h1 {
  text-transform: uppercase;
  font-weight: 900;
  border-left: 10px solid #fec500;
  padding-left: 10px;
  margin-bottom: 30px
} */

/* .row {
  overflow: hidden
} */

.card-ticket {
  display: table-row;
  width: 100%;
  background-color: #fff;
  color: #989898;
  margin-bottom: -7px;
  font-family: 'Oswald', sans-serif;
  /* text-transform: uppercase; */
  border-radius: 4px;
  position: relative
}

.card-ticket+.card-ticket {
  margin-left: 2%
}

.date {
  display: table-cell;
  width: 30%;
  position: relative;
  text-align: center;
  border-right: 2px dashed #dadde6
}

.date:before,
.date:after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-color: #DADDE6;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 1;
  border-radius: 50%;
 
}

.date:after {
  top: auto;
  bottom: -15px
}

.date time {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.date time span {
  display: block
}

.date time span:first-child {
  color: #2b2b2b;
  font-weight: 600;
  font-size: 250%
}

.date time span:last-child {
  /* text-transform: uppercase; */
  font-weight: 600;
  margin-top: -10px
}

.card-cont-1 {
  display: table-cell;
  width: 75%;
  font-size: 85%;
  padding: 10px 10px 30px 50px
}

.card-cont-1 h3 {
  color: #3C3C3C;
  font-size: 130%
}

.row:last-child .card:last-of-type .card-cont-1 h3 {
  text-decoration: line-through
}

.card-cont-1>div {
  display: table-row
}

.card-cont-1 .even-date i,
.card-cont-1 .even-info i,
.card-cont-1 .even-date time,
.card-cont-1 .even-info p {
  display: table-cell
}

.card-cont-1 .even-date i,
.card-cont-1 .even-info i {
  padding: 5% 5% 0 0
}

.card-cont-1 .even-info p {
  padding: 30px 50px 0 0
}

.card-cont-1 .even-date time span {
  display: block
}

.card-cont-1 a{
  display: block;
  text-decoration: none;
  width: 80px;
  height: 30px;
  background-color: #5645ebd5;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  bottom: 10px
}
.gobtn{
   text-shadow:2px 2px #000;
}


@media screen and (max-width: 860px) {
  .card {
    display: block;
    float: none;
    width: 100%;
    margin-bottom: 10px
  }

  .card-ticket+.card-ticket {
    margin-left: 0
  }

  .card-cont-1 .even-date,
  .card-cont-1 .even-info {
    font-size: 75%
  }
}