@media screen and (max-width: 2000px) {
.cardp{
    float:left;
    padding:0px 0px;
    margin:30px 0px 0px 0px;
    position:relative;
    height:50%;
    border-radius: 20px;
    margin-top: 20%;
    width:10px;
    justify-content: space-between;
    align-items: center;
  }

.cover{
    border-radius: 20px;
    position:absolute;
    height:18rem;
    width:18rem;
    background:rgba(28, 28, 28, 0.736);
    display:none;
    animation-name: cvr;
    animation-duration: 0.30s;
    overflow:hidden;
    animation-timing-function:linear;
}


.cardp:hover .cover{
    display:block;
}

@keyframes cvr {
    /* from {right: 300px;}
    to {right: 0px;} */
    from { transform: scale(0%); 
        -webkit-transform: scale(0%); 
        -moz-transform: scale(0%); 
        -ms-transform: scale(0%); 
        -o-transform: scale(0%); }
    to { transform:scale(100%) ; 
        -webkit-transform:scale(100%) ; 
        -moz-transform:scale(100%) ; 
        -ms-transform:scale(100%) ; 
        -o-transform:scale(100%) ; }
  }

.b1,.b2{
    height:50px;
    width:150px;
    color:#fff;
    border-radius: 10px ;
}   
@keyframes btnn {
    from {margin: 100px 0px 0px 200px;}
    to {margin:100px 0px 0px 63px;}
  }
  @keyframes btnnn {
    from {margin: 10px 200px 0px 0px;}
    to {margin:10px 0px 0px 63px;}
  }

.b1{
    background-color: #0a2254;
    margin:100px 0px 0px 63px;
    border: 0px;
    animation-name: btnn;
    animation-duration: 1s;
    box-shadow: 2px 2px 5px #000;
    }
.b2{
    border: 1px solid #0a2254;
    background-color: rgba(162, 162, 162, 0.067);
    margin:10px 0px 0px 63px;
    animation-name: btnnn;
    animation-duration: 1s;
    box-shadow: 2px 2px 5px #000;
}

.b1:hover{
    border: 1px solid #0a2254;
    background: rgba(162, 162, 162, 0.067);
}
.b2:hover{
    background: #0a2254;
}
.wdt{
    margin:3% auto;
}

.cardp img{
    height:90%;
    border-radius: 20px;
}
#carouselExampleControls{
   box-shadow: none;
}

.slide {
    max-width: 1160px;
}
.nn{
    padding:0px 180px;
}


.bdr{
    display:flex;
    padding:10px;
    width:100%;
    justify-content: center;
    align-items: center;
    /* border: 2px solid #0a2254;  */
}

    .offer{
    width:75px;
    text-align: center;
    position:absolute;
    font-weight:bold;
    margin-top:10%;
}
.salePrice{
    font-size:22px;
    color:#000;
    font-weight:500;
}
.offer-main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100px;
    float:left; 
    position:relative;
}


}



/* responsive 2 */
@media screen and (max-width: 1200px) {

    .nn{
        padding:0px 100px;
    }

    .slide {
        max-width: 760px;
    }
    .card{
        height:30%;
    }
    .card img{
        height:60%;
        border-radius: 20px;
    }
    .nn{
        padding:0px 100px;
    }
  }



  @media screen and (max-width: 1050px) {

    .salePrice{
        font-size: 17px;
    }
    .nn{
        padding:0px 50px;
    }
    .slide1 {
     width: 330px;
     margin-left: 60px;
     margin-top: 40%;
     height: 240px;
     border-radius: 25px 25px 0px 0px;
     -webkit-border-radius: 25px 25px 0px 0px;
     -moz-border-radius: 25px 25px 0px 0px;
     -ms-border-radius: 25px 25px 0px 0px;
     -o-border-radius: 25px 25px 0px 0px;
}

.offer{
    font-size:11px;
}

    }



/* responsive 3 */

    @media screen and (max-width: 830px) {


    @keyframes btnn {
        from {margin: 100px 0px 0px 200px;}
        to {margin:100px 0px 0px 120px;}
      }
      @keyframes btnnn {
        from {margin: 10px 200px 0px 0px;}
        to {margin:10px 0px 0px 120px;}
      }
    .b1{
        margin:100px 0px 0px 120px;
    }
    .b2{
        margin:10px 0px 0px 120px;
    }

    .nn{
        width:1200px;
        padding:0px 0px 0px 0px;
    }

    .salePrice{
        font-size: 26px;
    }
    .offer{
        font-size:17px;
    }


}


/* responsive */

@media screen and (max-width: 830px) {

    .nn{
        width:1300px;
    }


}
/* responsive */



@media screen and (max-width: 550px) {

    .nn{
        width:1000px;
        padding:0px 0px 0px 0px;
    }


}

@media screen and (max-width: 450px) {

    .nn{
        width:1100px;
    }


}
    