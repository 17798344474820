.st_bt_top_header_wrapper {
    background: #2b2d3d;
    padding-top: 32px;
    padding-bottom: 32px;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.float_left {
    float: left;
    width: 100%;
}
.st_bt_top_back_btn a {
    float: left;
    width: 100px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    background: #3f414f;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.38);
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_bt_top_center_heading {
    text-align: center;
}
.st_bt_top_center_heading h3 {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.38);
    letter-spacing: 0.62px;
    text-transform: uppercase;
    padding-top: 6px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Poppins", sans-serif;
    line-height: 1.1;
    font-weight: 400;
    color: #222222;
    margin: 0;
    padding: 0;
}
.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.st_dtts_ineer_box {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
    padding: 20px;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.st_dtts_ineer_box li:first-child {
    margin-top: 0;
}
.st_dtts_ineer_box li {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    float: left;
    width: 100%;
}
.dtts1 {
    float: left;
    width: 230px;
}
.dtts2 {
    float: left;
    width: calc(100% - 230px);
}
.st_dtts_ineer_box li {
    margin-top: 25px;
}
.st_dtts_ineer_box li {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    float: left;
    width: 100%;
}
.dtts1 {
    float: left;
    width: 230px;
}
.dtts2 {
    float: left;
    width: calc(100% - 230px);
    font-family: "Lato", sans-serif;
}
.st_cherity_btn h3 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0;
    text-transform: uppercase;
    padding-top: 30px;
    padding-bottom: 30px;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.st_cherity_btn li {
    float: left;
    margin-right: 30px;
}
.st_cherity_btn li:first-child a {
    width: 122px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    float: left;
    font-size: 14px;
    background: #333545;
    color: #ffffff;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
a {
    text-decoration: none;
}
st_cherity_btn li:nth-child(2) a {
    width: 196px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    float: left;
    font-size: 14px;
    background: #333545;
    color: #ffffff;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_cherity_btn li:last-child a {
    width: 196px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    float: left;
    background: #dbdbdb;
    font-size: 14px;
    font-weight: 800;
    color: darkgreen;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_cherity_btn li:nth-child(2) a {
    width: 196px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    float: left;
    font-size: 14px;
    background: #333545;
    color: #ffffff;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.st_cherity_btn li:last-child {
    margin-right: 0;
    float: right;
}
.st_dtts_wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
}
.st_dtts_bs_wrapper {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
}
.st_dtts_bs_heading {
    background: #333545;
    padding: 12px 20px;
    border-radius: 4px;
}
.st_dtts_bs_heading p {
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 1px;
    text-align: center;
}
.st_dtts_sb_ul {
    padding: 20px;
    padding-bottom: 0px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.54);
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.st_dtts_sb_ul li:first-child {
    margin-top: 0;
}
.st_dtts_sb_ul li {
    font-size: 14px;
    color: #333545;
    letter-spacing: 1px;
    float: left;
    width: 100%;
}
.st_dtts_sb_ul li span {
    float: right;
    margin-top: -22px;
}
.st_dtts_sb_ul li:last-child span {
    margin-top: 0;
}
.st_dtts_sb_ul li span {
    float: right;
}
.st_dtts_sb_ul p {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.38);
    letter-spacing: 1px;
    float: left;
    width: 100%;
    padding-left: 10px;
}
.st_dtts_sb_ul p span {
    float: right;
}
.st_dtts_sb_ul p {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.38);
    letter-spacing: 1px;
    float: left;
    width: 100%;
    padding-left: 10px;
}

.st_dtts_sb_ul p span {
    float: right;
}
.st_dtts_sb_h2 {
    padding: 20px;
}
.st_dtts_sb_h2 h3 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    letter-spacing: 0;
    font-weight: 800;
}
.st_dtts_sb_h2 h3 span {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    letter-spacing: 0;
    float: right;
}
.st_dtts_sb_h2 h4 {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
    letter-spacing: 1px;
    text-align: center;
    line-height: 14px;
    padding-top: 20px;
}
.st_dtts_sb_h2 h4 span {
    color: #f06292;
}
.st_dtts_sb_h2 h5 {
    padding-top: 30px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0;
    font-weight: 800;
}
.st_dtts_sb_h2 h5 span {
    float: right;
}
