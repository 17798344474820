#cardname:hover{
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 39%);
  cursor: pointer;
}

#cardname:hover #clsbtnn{
display:block;
font-size:25px;
}

#clsbtnn{
  transition: .30s linear;
  display:none;
}
    
