  @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');


/* .card11{
    height: 90%;
    width: 90%;
} */

.mp{
    font-family: 'Quicksand', sans-serif;
}

