.prs_title_main_sec_wrapper {
    /* background: url(./banner.jpg) 50% 0 repeat-y; */
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: cover;
    /* width: 100%; */
    height: 100%;
    float: left;
    position: relative;
    padding-top: 75px;
    text-align: center;
}
.st_bcc_main_main_wrapper {
    text-align: center;
}
.float_left {
    float: left;
    width: 100%;
}
.st_bcc_main_wrapper {
    /* padding-top: 100px; */
    /* padding-bottom: 100px; */
    display: inline-block;
    width: 80%;
    text-align: left;
    margin-top: -5rem;
    margin-left: 4rem;
}
.st_bcc_heading_wrapper {
    text-align: center;
    border-bottom: 2px solid #d8d8d8;
    padding-bottom: 60px;
}
.st_bcc_heading_wrapper i {
    font-size: 60px;
    color: #6cc77c;
}
.float_left {
    float: left;
    width: 100%;
}
.prs_title_heading_wrapper h2 {
    font-size: 36px;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
}
.prs_title_heading_wrapper ul {
    display: inline-block;
    background: rgba(255, 255, 255, 0.13);
    padding: 10px 30px;
    margin-top: 40px;
    position: relative;
    top: 4px;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.prs_title_heading_wrapper li {
    float: left;
    color: #ff4444;
}
i:before {
    margin-left: 0 !important;
}
.st_bcc_heading_wrapper i {
    font-size: 60px;
    color: #6cc77c;
}
.st_bcc_heading_wrapper {
    text-align: center;
    border-bottom: 2px solid #d8d8d8;
    padding-bottom: 60px;
}
.float_left {
    float: left;
    width: 100%;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.st_bcc_heading_wrapper h3 {
    padding-top: 30px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.54);
    letter-spacing: 0.5px;
}
.st_bcc_heading_wrapper h3 span {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.5px;
    font-weight: 600;
}
.st_bcc_ticket_boxes_wrapper {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
    position: relative;
    margin-top: 60px;
    padding: 30px;
}
.st_bcc_tecket_top_hesder {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 60px;
    border-bottom: 1px dashed #979797;
}
.st_bcc_tecket_top_hesder p {
    font-size: 24px;
    color: #ff4444;
    text-transform: uppercase;
    font-weight: 600;
}
.st_bcc_tecket_top_hesder span {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.38);
    text-align: center;
    font-weight: 600;
    padding-top: 10px;
    display: inline-block;
}
.st_bcc_tecket_bottom_hesder {
    background: rgba(216, 216, 216, 0.12);
    padding: 30px px;
    margin-top: 40px;
}
.float_left {
    float: left;
    width: 100%;
}
.st_bcc_tecket_bottom_left_wrapper {
    float: left;
    width: calc(100% - 200px);
    position: relative;
}
.st_bcc_tecket_bottom_inner_left {
    float: left;
    width: auto;
}
.st_bcc_teckt_bot_inner_img {
    float: left;
    width: auto;
}
.st_bcc_teckt_bot_inner_img_cont {
    float: left;
    width: auto;
    padding-left: 20px;
}
.st_bcc_teckt_bot_inner_img_cont h4 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.57px;
    font-weight: 800;
}
.st_bcc_teckt_bot_inner_img_cont h5 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.7px;
    padding-top: 5px;
    font-weight: 500;
}
.st_bcc_teckt_bot_inner_img_cont h3 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.7px;
    padding-top: 10px;
    font-weight: 500;
}
.st_bcc_teckt_bot_inner_img_cont h6 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.7px;
    font-weight: 500;
    padding-top: 15px;
    line-height: 19px;
}
.st_purchase_img {
    position: absolute;
    top: 50px;
    right: 220px;
}
.st_bcc_tecket_bottom_inner_right {
    float: right;
    width: auto;
    text-align: center;
}
.st_bcc_tecket_bottom_inner_right i {
    color: #111;
    font-size: 26px;
}
.st_bcc_tecket_bottom_inner_right h3 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 21px;
    font-weight: 600;
    padding-top: 10px;
}
.st_bcc_tecket_bottom_inner_right h3 span {
    font-size: 12px;
}
.st_bcc_tecket_bottom_right_wrapper {
    float: left;
    width: 200px;
    text-align: center;
    padding-left: 47px;
}
.st_bcc_tecket_bottom_right_wrapper h4 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 1px;
    padding-top: 10px;
    line-height: 25px;
}
.st_bcc_tecket_bottom_left_price_wrapper {
    float: left;
    width: calc(100% - 200px);
}
st_bcc_tecket_bottom_left_price_wrapper h4 {
    float: left;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.9px;
    font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Poppins", sans-serif;
    line-height: 1.1;
    font-weight: 400;
    color: #222222;
    margin: 0;
    padding: 0;
}
.st_bcc_tecket_bottom_left_price_wrapper h5 {
    float: right;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.9px;
    font-weight: 700;
}
.st_bcc_ticket_boxes_bottom_wrapper {
    text-align: center;
}
.st_bcc_ticket_boxes_bottom_wrapper p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.57px;
    text-align: center;
    line-height: 24px;
    margin-top: 30px;
}
.st_bcc_ticket_boxes_bottom_wrapper ul {
    display: inline-block;
    margin-top: 30px;
}
.st_bcc_ticket_boxes_bottom_wrapper li {
    float: left;
    margin-right: 10px;
}
.st_bcc_ticket_boxes_bottom_wrapper li:last-child a {
    width: 150px;
    height: 50px px;
    line-height: 50px;
    text-align: center;
    float: left;
    background: transparent;
    font-size: 12px;
    font-weight: 600;
    color: #ff4444;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid #ff4444;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
a {
    color: #797979;
    text-decoration: none;
}
.st_bcc_ticket_boxes_wrapper:after {
    content: "";
    width: 50px;
    height: 50px;
    background: rgb(247, 247, 247);
    position: absolute;
    left: -25px;
    top: 30%;
    border-radius: 100%;
}
.st_bcc_ticket_boxes_wrapper:before {
    content: "";
    width: 50px;
    height: 50px;
    background: rgb(247, 247, 247);
    position: absolute;
    right: -25px;
    top: 30%;
    border-radius: 100%;
}
/* body,
html {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: #707070;
    padding-right: 0 !important;
    overflow-x: hidden;
} */
.st_bcc_tecket_top_hesder {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 60px;
    border-bottom: 1px dashed #979797;
}
.float_left {
    float: left;
    width: 100%;
}

.st_bcc_tecket_bottom_hesder {
    background: rgba(216, 216, 216, 0.12);
    padding: 30px;
    margin-top: 40px;
}
.st_bcc_teckt_bot_inner_img_cont {
    float: left;
    width: auto;
    padding-left: 20px;
}
.st_bcc_teckt_bot_inner_img_cont h4 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.57px;
    font-weight: 800;
}
.st_bcc_teckt_bot_inner_img_cont h5 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.7px;
    padding-top: 5px;
    font-weight: 500;
}
.st_bcc_teckt_bot_inner_img_cont h3 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.7px;
    padding-top: 10px;
    font-weight: 500;
}
.st_bcc_teckt_bot_inner_img_cont h6 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.7px;
    font-weight: 500;
    padding-top: 15px;
    line-height: 19px;
}
.st_bcc_tecket_bottom_left_price_wrapper {
    float: left;
    width: calc(100% - 200px);
}
.st_bcc_tecket_bottom_left_price_wrapper h4 {
    float: left;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.9px;
    font-weight: 700;
}
.st_bcc_tecket_bottom_left_price_wrapper h5 {
    float: right;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.9px;
    font-weight: 700;
}
.st_bcc_ticket_boxes_wrapper:before {
    content: "";
    width: 50px;
    height: 50px;
    background: rgb(247, 247, 247);
    position: absolute;
    right: -25px;
    top: 30%;
    border-radius: 100%;
}
.st_bcc_tecket_bottom_hesder {
    background: rgba(216, 216, 216, 0.12);
    padding: 30px;
    margin-top: 40px;
}
.st_bcc_tecket_bottom_inner_right {
    float: right;
    width: auto;
    text-align: center;
}
.st_purchase_img {
    position: absolute;
    top: 50px;
    right: 220px;
}
@media (max-width: 991px) {
    .st_purchase_img {
        display: none;
    }
}

@media (max-width: 991px) {
    .st_bcc_tecket_bottom_inner_right h3 {
        text-align: left;
    }
}
@media (max-width: 991px) {
    .st_bcc_tecket_bottom_inner_right {
        width: 100%;
        text-align: left;
        margin-top: 20px;
    }
}
